 .e-pivotview {
  height: 90vh !important;
}

.control-section {
  height: 90vh !important;
  position: absolute !important;
  width: 100vw !important;
}

.e-headercell .e-leftfreeze .e-freezeleftborder .e-group-row {
  height: 40px !important;
  max-height: 40px !important;
  overflow-y: scroll !important;
}

.e-gridcontent {
  height: 400px !important;
}

.e-gridcontent > .e-content {
  height: 100% !important;
}
