.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-wrapper .e-card.e-draggable,
.e-kanban .e-kanban-content .e-content-row .e-content-cells .e-card-container .e-card.e-draggable {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.e-kanban .e-kanban-table .e-header-cells {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells .e-card-wrapper,
.e-kanban .e-kanban-content .e-content-row:not(.e-swimlane-row) .e-content-cells .e-card-container {
    border-radius: 4px;
}

.e-kanban .e-kanban-table {
    border-spacing: 6px 4px !important;
    background-color: #E0E0E0 !important;
}

.e-kanban .e-kanban-header .e-header-cells {
    width: 23.4vw;
}

.e-kanban .e-kanban-content .e-content-row .e-content-cells {
    width: 23.4vw;
}

.e-card-wrapper {
    background-color: #F5F5F5 !important;
}

.e-kanban .e-kanban-content {
    padding: 0;
    height: 75vh !important;
    overflow-y: hidden !important;
}

.e-kanban .card-template-wrap td {
    background: none !important;
}

.e-kanban .card-template-wrap .CardHeader {
    font-weight: 500;
}

.e-kanban .e-card.e-selection {
    background-color: #fff !important;
}

.e-card-content {
    color: #535353 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.e-kanban .e-card.persist-selection {
    background-color: #51DACF70 !important;
}

.e-kanban .e-card-tag {
    background-color: #00ACC1 !important;
    color: #FFF !important;
    font-weight: 500 !important;
}